import { TRADING_PLATFORM_URL, DEFAULT_PRODUCERS_LINK } from '../../../constants';

const HOTLINE_CONTACT = {
    link: 'tel:030 325 000 0',
    label: '030 325 000 00'
};

export const HEADER_NAVIGATION_LINKS = {
    home: {
        link: '/',
        label: 'Home'
    },
    goodEnergy: {
        link: '/good-energy',
        label: 'Gute Energie'
    },
    producers: {
        link: DEFAULT_PRODUCERS_LINK,
        label: 'Ökostrom-Marktplatz'
    },
    solar: {
        link: '/solar',
        label: 'Solaranlagen',
        icon: '/static/header/solar-plant.svg'
    },
    faq: {
        link: '/faq',
        label: 'FAQ'
    },
    aboutUs: {
        link: '/about-us',
        label: 'Über Uns'
    },
    contact: {
        link: '#footer',
        label: 'Kontakt'
    },
    tradingPlatform: {
        link: TRADING_PLATFORM_URL,
        icon: '/static/header/tp-login.svg'
    },
    phone: {
        link: HOTLINE_CONTACT.link,
        label: HOTLINE_CONTACT.label,
        icon: '/static/header/phone.svg'
    },
    ladestrom: {
        link: '/ladestrom',
        label: 'Ladestrom'
    },
    heizstrom: {
        link: '/heizstrom',
        label: 'Heizstrom'
    },
    gas: {
        link: '/gas',
        label: 'Gas',
        icon: '/static/header/flame.svg'
    }
};

export const DEFAULT_LABELS = {
    customerLogin: 'Kunden Login',
    logo: {
        link: '/',
        sources: {
            url: '/static/header/logo.svg',
            mobileUrl: '/static/header/mobile-logo.svg'
        },
        isMobileLogo: false
    },
    navigationLinks: {
        left: [
            {
                label: 'Ökostrom',
                icon: '/static/header/leaf.svg',
                dropdownLinks: [
                    HEADER_NAVIGATION_LINKS.producers,
                    HEADER_NAVIGATION_LINKS.ladestrom,
                    HEADER_NAVIGATION_LINKS.heizstrom
                ]
            },
            HEADER_NAVIGATION_LINKS.solar
        ],
        right: [
            HEADER_NAVIGATION_LINKS.goodEnergy,
            HEADER_NAVIGATION_LINKS.aboutUs,
            {
                label: 'Service',
                dropdownLinks: [HEADER_NAVIGATION_LINKS.faq, HEADER_NAVIGATION_LINKS.contact]
            }
        ]
    },
    menuItems: [
        {
            label: 'Ökostrom',
            icon: '/static/header/leaf.svg',
            dropdownLinks: [
                HEADER_NAVIGATION_LINKS.producers,
                HEADER_NAVIGATION_LINKS.ladestrom,
                HEADER_NAVIGATION_LINKS.heizstrom
            ]
        },
        HEADER_NAVIGATION_LINKS.solar,
        HEADER_NAVIGATION_LINKS.goodEnergy,
        HEADER_NAVIGATION_LINKS.aboutUs,
        {
            label: 'Service',
            dropdownLinks: [HEADER_NAVIGATION_LINKS.faq, HEADER_NAVIGATION_LINKS.contact]
        }
    ],
    menuIcons: {
        open: '/static/header/menu-open.svg',
        close: '/static/header/menu-close.svg'
    },
    socialNetworksLinks: [
        {
            url: 'https://www.linkedin.com/company/litionenergygmbh',
            svg: '/static/pics/socialNetworks/linkedIn.svg'
        },
        {
            url: 'https://www.facebook.com/LitionEnergyDE',
            svg: '/static/pics/socialNetworks/facebook.svg'
        },
        {
            url: 'https://twitter.com/lition_energy',
            svg: '/static/pics/socialNetworks/twitter.svg'
        },
        {
            url: 'https://www.instagram.com/lition_energie',
            svg: '/static/pics/socialNetworks/instagram.svg'
        },
        {
            url: 'https://www.youtube.com/channel/UCQCGDEGSwl8qKtCrr9kPVBw',
            svg: '/static/pics/socialNetworks/youtube.svg'
        },
        {
            url: 'https://medium.com/lition-energie',
            svg: '/static/pics/socialNetworks/medium.svg'
        }
    ]
};

export const MOBILE_SIZE_DISPLAY = 700;
export const MIDDLE_SIZE_DISPLAY = 1080;
export const SHOW_ACTIVE_LINK_BORDER_TIME = 500;
export const ARROW_UP = '/static/pics/arrowUp.svg';
export const ARROW_DOWN = '/static/pics/arrowDown.svg';
export const WITH_ANIMATION_BORDER = true;
export const WITH_LINK_DELIMITER = true;
export const HANDLE_SCROLL_THROTTLE_TIME = 120;
