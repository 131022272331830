import React from 'react';
import PropTypes from 'prop-types';

import { DEFAULT_LABELS } from './constants';

export default function Logo({ link, isMobileLogo, sources }) {
    return (
        <a className="ltn-header-v2-logo" href={link}>
            {isMobileLogo ? (
                <img src={sources.mobileUrl} height={27} width={27} alt="" />
            ) : (
                <img src={sources.url} height={27} width={126} alt="" />
            )}
        </a>
    );
}

Logo.propTypes = {
    link: PropTypes.string,
    sources: PropTypes.shape({
        url: PropTypes.string,
        mobileUrl: PropTypes.string
    }),
    isMobileLogo: PropTypes.bool
};

Logo.defaultProps = {
    link: DEFAULT_LABELS.logo.link,
    sources: DEFAULT_LABELS.logo.sources,
    isMobileLogo: DEFAULT_LABELS.logo.isMobileLogo
};
