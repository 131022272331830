import React from 'react';
import PropTypes from 'prop-types';

import { TRADING_PLATFORM_URL } from '../../../constants';

import { DEFAULT_LABELS, HEADER_NAVIGATION_LINKS } from './constants';
import NavigationLinkWrapper from './NavigationLinkWrapper';

export default function Menu({ socialNetworksLinks, menuItems, pagePath, onCloseMenu }) {
    return (
        <div className="ltn-header-v2-mobile-menu">
            <div className="ltn-header-v2-menu-item">
                <img
                    className="ltn-header-v2-mobile-menu-icon"
                    src={DEFAULT_LABELS.logo.sources.mobileUrl}
                    width={27}
                    height={27}
                    alt=""
                />
                <a className="ltn-header-v2-menu-item-link" href={DEFAULT_LABELS.logo.link}>
                    {HEADER_NAVIGATION_LINKS.home.label}
                </a>
            </div>
            <NavigationLinkWrapper
                navigationLinks={{ center: menuItems }}
                withDelimiter={false}
                onNavigationLinkClick={onCloseMenu}
                pagePath={pagePath}
                withAnimationBorder={false}
            />
            <div className="ltn-header-v2-menu-item ltn-header-v2-menu-item-border-top">
                <img
                    className="ltn-header-v2-mobile-menu-icon"
                    src={HEADER_NAVIGATION_LINKS.tradingPlatform.icon}
                    width={28}
                    height={28}
                    alt=""
                />
                <a className="ltn-header-v2-menu-item-link" href={TRADING_PLATFORM_URL}>
                    {DEFAULT_LABELS.customerLogin}
                </a>
            </div>
            <ul className="ltn-header-v2-social-networks">
                {socialNetworksLinks.map(socialNetwork => (
                    <li key={socialNetwork.url}>
                        <a href={socialNetwork.url} target="_blank" rel="noreferrer">
                            <img src={socialNetwork.svg} width={35} height={35} alt="" />
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

Menu.propTypes = {
    menuItems: PropTypes.arrayOf(PropTypes.shape()),
    socialNetworksLinks: PropTypes.arrayOf(PropTypes.shape()),
    pagePath: PropTypes.string,
    onCloseMenu: PropTypes.func
};

Menu.defaultProps = {
    menuItems: DEFAULT_LABELS.menuItems,
    socialNetworksLinks: DEFAULT_LABELS.socialNetworksLinks,
    pagePath: undefined,
    onCloseMenu: () => {}
};
