import './index.css';
import React from 'react';

import PropTypes from 'prop-types';
import get from 'lodash/get';

import * as GTM from '../../../services/helpers/gtm';

import Adapter from '../../../services/adapters/contentful';
import FooterV2 from '../FooterV2';
import HeaderV2 from '../HeaderV2';

class MainContainer extends React.PureComponent {
    renderHeader() {
        const { content: { isPageInIframe, headerContent } = {}, showHeader, pagePath } = this.props;

        if (isPageInIframe || !showHeader) {
            return null;
        }

        const content = Adapter.getAdaptedHeaderContent(headerContent?.fields);

        return (
            <HeaderV2
                menuItems={content?.menuItems}
                navigationLinks={content?.navigationLinks}
                withAnimationBorder={content?.withAnimationBorder}
                pagePath={pagePath}
            />
        );
    }

    renderFooter() {
        const { content: { footerContent, isPageInIframe } = {}, showFooter } = this.props;

        if (isPageInIframe || !showFooter || !footerContent) {
            return null;
        }

        const getSocialLinkAndIconForFooter = socialLogos => {
            if (!socialLogos) {
                return undefined;
            }

            return socialLogos.map(logo => ({
                svg: get(logo, 'fields.file.url'),
                url: get(logo, 'fields.description')
            }));
        };

        const toNavigationItem = contentfulItems => contentfulItems
            .filter(entry => get(entry, 'sys.space.sys.type') === 'Link')
            .map(entry => ({
                href: get(entry, 'fields.url', ''),
                label: get(entry, 'fields.text')
            }));

        return (
            <FooterV2
                key="Footer"
                id="footer"
                logoSource={{ svg: get(footerContent, 'logo.fields.file.url') }}
                socialLogos={getSocialLinkAndIconForFooter(get(footerContent, 'socialLogos'))}
                headlineTexts={{
                    newsletterLink: get(footerContent, 'labels.sectionHeadings.newsletterLink'),
                    contact: get(footerContent, 'labels.sectionHeadings.contact', ''),
                    community: get(footerContent, 'labels.sectionHeadings.community', ''),
                    legal: get(footerContent, 'labels.sectionHeadings.legal', ''),
                    explore: get(footerContent, 'labels.sectionHeadings.explore', '')
                }}
                contactInfo={{
                    hotLineHtml: get(footerContent, 'hotLineHtml'),
                    email: get(footerContent, 'email', ''),
                    addressHtml: get(footerContent, 'addressHtml', '')
                }}
                navItems={[
                    {
                        headlineText: get(footerContent, 'labels.sectionHeadings.explore', ''),
                        items: toNavigationItem(get(footerContent, 'exploreNavigationItems', []))
                    },
                    {
                        headlineText: get(footerContent, 'labels.sectionHeadings.legal', ''),
                        items: toNavigationItem(get(footerContent, 'navigationItems', []))
                    }
                ]}
                newsletterLinkLabel={footerContent?.labels?.newsletterLink}
                newsletterLink={footerContent?.newsletterLink}
                onSocialIconClick={GTM.addSocialIconClickDataToDL}
            />
        );
    }

    render() {
        const { className, style, children, content: { isPageInIframe } = {}, showHeader } = this.props;

        return (
            <div id="ltn-main-container" lang="de">
                <div
                    className={`${className} ${!isPageInIframe && showHeader && 'ltn-main-content-padding'}`}
                    style={style}
                    role="main"
                >
                    {children}
                </div>
            </div>
        );
    }
}

MainContainer.propTypes = {
    className: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    children: PropTypes.node,
    content: PropTypes.shape({
        footerContent: PropTypes.shape(),
        tariffCalculationForm: PropTypes.shape(),
        isPageInIframe: PropTypes.bool
    }),
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    pagePath: PropTypes.string
};

MainContainer.defaultProps = {
    className: '',
    style: {},
    children: null,
    content: {
        footerContent: undefined,
        tariffCalculationForm: undefined,
        isPageInIframe: false
    },
    showHeader: true,
    showFooter: true,
    pagePath: undefined
};

export default MainContainer;
