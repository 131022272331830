import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function NavigationLink({ icon, link, onClick, label, showLinkBorder, showActiveLinkBorder, isActive }) {
    const classes = classNames('ltn-navigation-v2-link', isActive && 'ltn-navigation-v2-link--active');

    return (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <a
            className={classes}
            href={link}
            id={link}
            onClick={onClick}
            onMouseOver={() => showLinkBorder(link)}
            onMouseOut={showActiveLinkBorder}
        >
            <>
                {icon && <img src={icon} width={20} height={20} alt="" />}
                {label && <span className={classNames(icon && 'ltn-navigation-v2-link-icon-text')}>{label}</span>}
            </>
        </a>
    );
}

NavigationLink.propTypes = {
    link: PropTypes.string.isRequired,
    icon: PropTypes.string,
    label: PropTypes.string,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    showLinkBorder: PropTypes.func,
    showActiveLinkBorder: PropTypes.func
};

NavigationLink.defaultProps = {
    icon: undefined,
    isActive: false,
    label: undefined,
    onClick: () => {},
    showLinkBorder: () => {},
    showActiveLinkBorder: () => {}
};
