import React from 'react';
import PropTypes from 'prop-types';

import { ARROW_UP, ARROW_DOWN } from './constants';
import NavigationLink from './NavigationLink';

export default function DropdownNavigationLinks({
    link,
    onChangeAcitveDropdownLink,
    isActiveDropdownLink,
    onLinkClick
}) {
    const handleNaviagtionLinkClick = () => {
        onChangeAcitveDropdownLink();

        if (onLinkClick) {
            onLinkClick();
        }
    };

    return (
        <>
            <button
                className="ltn-header-v2-dropdown-nav-button"
                type="button"
                onClick={() => onChangeAcitveDropdownLink(link.label)}
            >
                {link.icon ? (
                    <img className="ltn-header-v2-dropdown-nav-icon" src={link.icon} height={20} width={20} alt="" />
                ) : null}
                <span>{link.label}</span>
                <img src={isActiveDropdownLink ? ARROW_UP : ARROW_DOWN} height={14} width={19} alt="" />
            </button>
            {isActiveDropdownLink && (
                <div onMouseLeave={onChangeAcitveDropdownLink}>
                    <span className="ltn-header-v2-nav-dropdown-background" />
                    <ul className="ltn-header-v2-nav-dropdown-link">
                        {link.dropdownLinks.map(dropdownLink => (
                            <li key={dropdownLink.link}>
                                <NavigationLink
                                    link={dropdownLink.link}
                                    label={dropdownLink.label}
                                    icon={dropdownLink.icon}
                                    onClick={handleNaviagtionLinkClick}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
}

DropdownNavigationLinks.propTypes = {
    link: PropTypes.shape().isRequired,
    onChangeAcitveDropdownLink: PropTypes.func.isRequired,
    isActiveDropdownLink: PropTypes.bool.isRequired,
    onLinkClick: PropTypes.func
};

DropdownNavigationLinks.defaultProps = {
    onLinkClick: () => {}
};
