import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SHOW_ACTIVE_LINK_BORDER_TIME, WITH_ANIMATION_BORDER, DEFAULT_LABELS, WITH_LINK_DELIMITER } from './constants';

import { getPagePathWithoutQueryParams } from '../../../services/helpers/url/url';

import NavigationLink from './NavigationLink';
import DropdownNavigationLinks from './DropdownNavigationLinks';

const isActiveLink = (pagePath, link) => (pagePath && link ? getPagePathWithoutQueryParams(pagePath) === getPagePathWithoutQueryParams(link) : false);

export default class NavigationLinkWrapper extends Component {
    constructor() {
        super();

        this.showHoverLinkBorder = this.showHoverLinkBorder.bind(this);
        this.showActiveLinkBorder = this.showActiveLinkBorder.bind(this);
        this.setActiveLink = this.setActiveLink.bind(this);
        this.handleActiveDropdownLink = this.handleActiveDropdownLink.bind(this);
        this.renderNavigationLinks = this.renderNavigationLinks.bind(this);

        this.state = {
            isShowLinkBorder: false,
            linkBorderWidth: '',
            linkBorderLeft: '',
            activeLink: null,
            activeDropdownLinkLabel: undefined
        };
    }

    componentDidMount() {
        setTimeout(() => this.setActiveLink(), SHOW_ACTIVE_LINK_BORDER_TIME);
    }

    componentDidUpdate(prevProps) {
        const { isActiveHeader } = this.props;

        if (prevProps.isActiveHeader !== isActiveHeader && !isActiveHeader) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ activeDropdownLinkLabel: undefined });
        }
    }

    handleActiveDropdownLink(label) {
        const { activeDropdownLinkLabel } = this.state;

        if (activeDropdownLinkLabel === label) {
            this.setState({ activeDropdownLinkLabel: undefined });
            return;
        }

        this.setState({ activeDropdownLinkLabel: label });
    }

    setActiveLink() {
        const { withAnimationBorder } = this.props;

        if (!withAnimationBorder) {
            return;
        }

        const currentLink = document.getElementsByClassName('ltn-navigation-v2-link--active');

        if (currentLink && currentLink.length) {
            const { offsetWidth, offsetLeft } = currentLink[0];

            if (offsetWidth && offsetLeft) {
                this.setState({
                    activeLink: currentLink[0],
                    isShowLinkBorder: true,
                    linkBorderWidth: `${offsetWidth}px`,
                    linkBorderLeft: `${offsetLeft}px`
                });
            }
        }
    }

    showHoverLinkBorder(link) {
        const { withAnimationBorder } = this.props;

        if (!withAnimationBorder || !link) {
            return;
        }

        const currentLink = document.getElementsByClassName('ltn-navigation-v2-link')[link];
        const { offsetWidth, offsetLeft, className } = currentLink;

        if (className === 'ltn-navigation-v2-link') {
            this.setState({
                isShowLinkBorder: true,
                linkBorderWidth: `${offsetWidth}px`,
                linkBorderLeft: `${offsetLeft}px`
            });
        }
    }

    showActiveLinkBorder() {
        const { withAnimationBorder } = this.props;
        const { activeLink } = this.state;

        if (withAnimationBorder && activeLink) {
            const { offsetWidth, offsetLeft } = activeLink;

            this.setState({
                linkBorderWidth: `${offsetWidth}px`,
                linkBorderLeft: `${offsetLeft}px`
            });
        } else {
            this.setState({ isShowLinkBorder: false });
        }
    }

    renderNavigationLinks(navigationLinks) {
        const { withDelimiter, onNavigationLinkClick, pagePath } = this.props;
        const { activeDropdownLinkLabel } = this.state;

        const itemClasses = classNames(
            'ltn-header-v2-links-group-item',
            withDelimiter && 'ltn-header-v2-links-group-item--with-delimiter'
        );

        return navigationLinks.map(link => (
            <li key={link.label || link.link} className={itemClasses}>
                {link.dropdownLinks ? (
                    <DropdownNavigationLinks
                        link={link}
                        isActiveDropdownLink={activeDropdownLinkLabel === link.label}
                        onChangeAcitveDropdownLink={this.handleActiveDropdownLink}
                        onLinkClick={onNavigationLinkClick}
                    />
                ) : (
                    <NavigationLink
                        link={link.link}
                        icon={link.icon}
                        label={link.label}
                        isActive={isActiveLink(pagePath, link.link)}
                        onClick={onNavigationLinkClick}
                        showLinkBorder={this.showHoverLinkBorder}
                        showActiveLinkBorder={this.showActiveLinkBorder}
                        setActiveLink={this.setActiveLink}
                    />
                )}
            </li>
        ));
    }

    render() {
        const {
            navigationLinks: { left, right, center }
        } = this.props;

        const { isShowLinkBorder, linkBorderWidth, linkBorderLeft } = this.state;

        return (
            <>
                {left && <ul className="ltn-header-v2-left-links-group">{this.renderNavigationLinks(left)}</ul>}
                {right && <ul className="ltn-header-v2-right-links-group">{this.renderNavigationLinks(right)}</ul>}
                {center && <ul className="ltn-header-v2-links-group">{this.renderNavigationLinks(center)}</ul>}
                <span
                    style={{ width: linkBorderWidth, left: linkBorderLeft }}
                    className={classNames(
                        'ltn-header-v2-link-border',
                        isShowLinkBorder && 'ltn-header-v2-link-border--active'
                    )}
                />
            </>
        );
    }
}

NavigationLinkWrapper.propTypes = {
    withDelimiter: PropTypes.bool,
    navigationLinks: PropTypes.shape(),
    onNavigationLinkClick: PropTypes.func,
    pagePath: PropTypes.string,
    withAnimationBorder: PropTypes.bool,
    isActiveHeader: PropTypes.bool
};

NavigationLinkWrapper.defaultProps = {
    withDelimiter: WITH_LINK_DELIMITER,
    navigationLinks: DEFAULT_LABELS.navigationLinks,
    onNavigationLinkClick: () => {},
    pagePath: undefined,
    withAnimationBorder: WITH_ANIMATION_BORDER,
    isActiveHeader: true
};
