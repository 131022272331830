import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DEFAULT_LABELS, DEFAULT_VALUES } from './constants';
import { emailIcon, phoneIcon, locationIcon } from './Icons';
import './Footer.css';

export default class FooterV2 extends Component {
    renderNavigationLink({ headlineText, items }) {
        return (
            <Fragment key={headlineText}>
                {headlineText && <h3 className="ltn-footer-v2-headline">{headlineText}</h3>}
                <ul className="ltn-footer-v2-navigation-links">
                    {items.map(item => (
                        <li key={item.href}>
                            <a href={item.href}>{item.label}</a>
                        </li>
                    ))}
                </ul>
            </Fragment>
        );
    }

    renderNavigationLinks() {
        const { navItems } = this.props;

        return (
            <nav className="ltn-footer-v2-navigation">
                {navItems.map(({ headlineText, items }) => this.renderNavigationLink({ headlineText, items }))}
            </nav>
        );
    }

    renderSocialNetworks() {
        const {
            socialLogos,
            headlineTexts: { community },
            onSocialIconClick
        } = this.props;

        if (!socialLogos) {
            return null;
        }

        return (
            <>
                <h3 className="ltn-footer-v2-headline ltn-footer-v2-newsletter-community-headline">{community}</h3>
                <ul className="ltn-footer-v2-community">
                    {socialLogos.map(logo => (
                        <li key={logo.svg} className="ltn-footer-v2-community__item">
                            <a
                                href={logo.url}
                                target="_blank"
                                rel="noopener noreferrer external"
                                aria-label={logo.url}
                                onClick={() => onSocialIconClick(logo.url)}
                            >
                                <img
                                    alt="Social Icon"
                                    className="lazyload"
                                    data-src={logo.svg}
                                    width={44}
                                    height={44}
                                />
                            </a>
                        </li>
                    ))}
                </ul>
            </>
        );
    }

    renderContacts() {
        const { headlineTexts, contactInfo } = this.props;

        return (
            <>
                <h3 className="ltn-footer-v2-headline">{headlineTexts.contact}</h3>
                <ul className="ltn-footer-v2-contacts">
                    <li>
                        <div className="ltn-footer-v2-hot-line-html">
                            <i className="ltn-footer-v2-icon">{phoneIcon}</i>
                            <span dangerouslySetInnerHTML={{ __html: contactInfo.hotLineHtml }} />
                        </div>
                    </li>
                    <li>
                        <a className="ltn-footer-v2-email" href={`mailto:${contactInfo.email}`}>
                            <i className="ltn-footer-v2-icon">{emailIcon}</i>
                            <span>{`E-Mail: ${contactInfo.email}`}</span>
                        </a>
                    </li>
                    <li>
                        <address className="ltn-footer-v2-address">
                            <i className="ltn-footer-v2-icon">{locationIcon}</i>
                            <span dangerouslySetInnerHTML={{ __html: contactInfo.addressHtml }} />
                        </address>
                    </li>
                </ul>
            </>
        );
    }

    renderNewsletterLink() {
        const { headlineTexts, newsletterLinkLabel, newsletterLink } = this.props;

        return (
            <>
                <h3 className="ltn-footer-v2-headline ltn-footer-v2-newsletter-community-headline">
                    {headlineTexts.newsletterLink}
                </h3>
                <a className="ltn-footer_v2-newsletter-link" href={newsletterLink}>
                    {newsletterLinkLabel}
                </a>
            </>
        );
    }

    renderLogo() {
        const { logoSource } = this.props;

        return (
            <small className="ltn-footer-v2-copyright">
                <span className="ltn-footer-v2-copyright-text">
                    &copy;
                    {new Date().getFullYear()}
                    {' '}
                    stromee
                    {' '}
                </span>
                <picture className="ltn-footer-v2-logo">
                    {logoSource.webp && <source srcSet={logoSource.webp} type="image/webp" />}
                    <img className="lazyload" data-src={logoSource.svg} alt="stromee logo" width={30} height={30} />
                </picture>
            </small>
        );
    }

    renderPartnerLogo() {
        const { logoSource } = this.props;
        return (
            <picture className="ltn-footer-v2-partner-logo">
                <img className="lazyload" data-src={logoSource.svg} alt={logoSource.alt || 'partner logo'} />
            </picture>
        );
    }

    render() {
        const { className, id, footerType, navItems } = this.props;

        // TODO: Move partner-footer render footer to Landing Footer when create it'
        if (footerType === 'partnerFooter') {
            return (
                <footer className={classNames('ltn-footer-v2', 'ltn-footer-v2-partner-footer', className)} id={id}>
                    <div className="ltn-footer-v2-layout">
                        <div className="ltn-mobile-and-full-size-footer-2">
                            <div className="ltn-footer-v2-sections">
                                <section className="ltn-footer-v2-section">
                                    <nav className="ltn-footer-v2-navigation">
                                        {this.renderNavigationLink(navItems[0])}
                                    </nav>
                                    {this.renderPartnerLogo()}
                                </section>
                                <section className="ltn-footer-v2-section">
                                    {this.renderNavigationLink(navItems[1])}
                                    {this.renderSocialNetworks()}
                                </section>
                                <section className="ltn-footer-v2-section">{this.renderContacts()}</section>
                            </div>
                        </div>
                        <div className="ltn-middle-size-footer-2">
                            <div className="ltn-footer-v2-sections">
                                <section className="ltn-footer-v2-section">
                                    {this.renderNavigationLink(navItems[0])}
                                    {this.renderPartnerLogo()}
                                    {this.renderNavigationLink(navItems[1])}
                                </section>
                                <section className="ltn-footer-v2-section">
                                    {this.renderSocialNetworks()}
                                    {this.renderContacts()}
                                </section>
                            </div>
                        </div>
                    </div>
                </footer>
            );
        }

        return (
            <footer className={classNames('ltn-footer-v2', className)} id={id}>
                <div className="ltn-footer-v2-layout">
                    <div className="ltn-mobile-and-full-size-footer-2">
                        <div className="ltn-footer-v2-sections">
                            <section className="ltn-footer-v2-section ltn-footer-v2-contacts-section">
                                {this.renderContacts()}
                            </section>
                            <section className="ltn-footer-v2-section">{this.renderNavigationLinks()}</section>
                            <section className="ltn-footer-v2-section">
                                {this.renderSocialNetworks()}
                                {this.renderNewsletterLink()}
                            </section>
                        </div>
                        {this.renderLogo()}
                    </div>
                    <div className="ltn-middle-size-footer-2">
                        <div className="ltn-footer-v2-sections">
                            <section className="ltn-footer-v2-section">
                                {this.renderContacts()}
                                {this.renderNewsletterLink()}
                            </section>
                            <section className="ltn-footer-v2-section">
                                {this.renderSocialNetworks()}
                                {this.renderNavigationLinks()}
                                {this.renderLogo()}
                            </section>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

FooterV2.propTypes = {
    className: PropTypes.string,
    headlineTexts: PropTypes.shape().isRequired,
    contactInfo: PropTypes.shape({
        hotLineHtml: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        addressHtml: PropTypes.string.isRequired
    }).isRequired,
    navItems: PropTypes.arrayOf(
        PropTypes.shape({
            headlineText: PropTypes.string.isRequired,
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    href: PropTypes.string.isRequired,
                    label: PropTypes.string.isRequired
                }).isRequired
            )
        })
    ).isRequired,
    logoSource: PropTypes.shape({
        webp: PropTypes.string,
        svg: PropTypes.string.isRequired,
        alt: PropTypes.string
    }).isRequired,
    id: PropTypes.string.isRequired,
    newsletterLinkLabel: PropTypes.string,
    newsletterLink: PropTypes.string,
    socialLogos: PropTypes.arrayOf(PropTypes.shape()),
    footerType: PropTypes.string,
    onSocialIconClick: PropTypes.func
};

FooterV2.defaultProps = {
    className: undefined,
    socialLogos: undefined,
    footerType: undefined,
    onSocialIconClick: f => f,
    newsletterLinkLabel: DEFAULT_LABELS.newsletterLink,
    newsletterLink: DEFAULT_VALUES.newsletterLink
};
