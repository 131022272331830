import React from 'react';

// TODO: Move to icons directory and add to styleguide
export const phoneIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width={18} height={18}>
        <path
            d="M8.5 4c.2 0 .3.3.4.4.1.1.2.2.3.4.5.7 1.1 1.6 1.5 2.3.5.9.9 1.6 1.3 2.5.2.4.2.5-.2.8-.5.5-1 .9-1.5 1.3-.8.8-.8.7-.4 1.7.6 2 1.7 3.7 3 5.2 1.1 1.2 2.3 2.2 3.7 3 .7.3 1.5.6 2.3.7.4 0 .8 0 1.1-.4.5-.5 1-1 1.5-1.4.5-.5.6-.5 1.2-.1 1.7.9 3.3 1.9 4.8 3.1.2.1.3.3.5.5v.1c-.2.2-.4.5-.7.7-.9.7-1.8 1.5-2.7 2.1-.6.4-1.2.9-2 1.1-.6.2-1.2 0-1.8-.1-1.8-.4-3.4-1.1-4.9-2.1-3.7-2.5-6.8-5.6-9.3-9.4-1.1-1.6-1.9-3.4-2.4-5.3-.3-1.2-.3-1.9.5-3 .7-.8 1.3-1.7 2-2.5.4-.4.7-.8 1.1-1.2.2-.1.5-.5.7-.4 0-.1 0-.1 0 0M27.9 15.5c-.7.1-1.4.2-2 .3 0-2.7-.9-5-2.8-6.9s-4.2-2.8-6.9-2.8c.1-.7.2-1.4.3-2h.7l1.8.3c4.4 1 7.9 4.8 8.6 9.2 0 .3.1.5.1.8.2.1.3 1.1.2 1.1"
            fill="currentColor"
        />
        <path
            d="M15.5 11.3c.1-.7.2-1.4.3-2 3.9-.5 7.4 2.9 7 7-.7.1-1.3.2-2 .3.2-1.6-.1-2.9-1.3-4-1.1-1.2-2.5-1.6-4-1.3"
            fill="currentColor"
        />
    </svg>
);

export const emailIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="3 2 25 25" width={24} height={17}>
        <path
            d="M1.8 23.2l8.8-7.5-8.8-7v14.5zm14.8-5.9L29.5 7c-.2-.2-.5-.3-.8-.3H3.2c-.3.1-.5.2-.7.3l12.9 10.2c.4.4.9.4 1.2.1zM29.5 25l-10-7.7-1.6 1.3c-.5.5-1.1.7-1.8.7s-1.4-.3-1.9-.8l-2.1-1.7L2.5 25c.2.1.5.2.8.2h25.6c.1 0 .4-.1.6-.2zm.7-1.7V8.8l-9.3 7.4 9.3 7.1zM32 8.2v15.6c0 1.8-1.5 3.2-3.2 3.2H3.2C1.5 27 0 25.6 0 23.8V8.2C0 6.4 1.5 5 3.2 5h25.6C30.5 5 32 6.4 32 8.2z"
            fill="currentColor"
        />
    </svg>
);

export const locationIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="5 15 21 1" width={14} height={20}>
        <path
            d="M25 12.8c0 .8-.1 1.6-.3 2.4-.1.2-.1.4-.2.6-.8 2.1-2.5 4.1-4 6.2-.9 1.2-4.1 5.6-4.5 6.2-.4-.5-3.6-4.9-4.5-6.2-1.5-2.1-3.2-4.1-4-6.2-.1-.2-.1-.4-.2-.6-.2-.8-.3-1.6-.3-2.4 0-5 4-9 9-9s9 4 9 9zm-5.3-.4c0-2-1.6-3.7-3.7-3.7-2 0-3.7 1.6-3.7 3.7 0 2 1.6 3.7 3.7 3.7 2 0 3.7-1.7 3.7-3.7z"
            fill="currentColor"
        />
    </svg>
);
