import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import throttle from 'lodash/throttle';

import { TRADING_PLATFORM_URL } from '../../../constants';

import {
    DEFAULT_LABELS,
    MOBILE_SIZE_DISPLAY,
    MIDDLE_SIZE_DISPLAY,
    HEADER_NAVIGATION_LINKS,
    HANDLE_SCROLL_THROTTLE_TIME
} from './constants';

import Logo from './Logo';
import NavigationLinkWrapper from './NavigationLinkWrapper';
import Menu from './Menu';

import './Header.css';

// Cover by tests resize and scroll functions
export default class HeaderV2 extends PureComponent {
    constructor() {
        super();

        this.handleScroll = throttle(this.handleScroll.bind(this), HANDLE_SCROLL_THROTTLE_TIME);
        this.handleResize = this.handleResize.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);

        this.header = React.createRef();

        this.state = {
            isMenuOpen: false,
            isShowShadow: false,
            isShowHeader: true,
            prevScrollPosition: 0,
            isShowMobileLogo: false
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
    }

    handleScroll() {
        this.hideHeader();
        this.shadowHeader();
    }

    handleResize() {
        const { isMenuOpen } = this.state;

        if (isMenuOpen && window && window.innerWidth > MIDDLE_SIZE_DISPLAY) {
            this.closeMenu();
        }
    }

    // TODO: Need to optimize
    hideHeader() {
        const { prevScrollPosition } = this.state;
        const currentScrollPosition = window.pageYOffset;
        let isShowHeader = true;
        let isShowMobileLogo = false;

        if (window.pageYOffset > this.header.current.offsetHeight) {
            if (prevScrollPosition < currentScrollPosition) {
                isShowHeader = false;
            }

            if (window.innerWidth < MOBILE_SIZE_DISPLAY) {
                isShowMobileLogo = true;
            }
        }

        this.setState({
            isShowHeader,
            isShowMobileLogo,
            prevScrollPosition: currentScrollPosition
        });
    }

    // TODO: Need to optimize
    shadowHeader() {
        const isShowShadow = window.pageYOffset > this.header.current.offsetHeight;
        this.setState({ isShowShadow });
    }

    openMenu() {
        window.document.documentElement.classList.add('ltn-header-v2-mobile-menu-is-open');
        this.setState({ isMenuOpen: true });
    }

    closeMenu() {
        window.document.documentElement.classList.remove('ltn-header-v2-mobile-menu-is-open');
        this.setState({ isMenuOpen: false });
    }

    toggleMenu() {
        const { isMenuOpen } = this.state;
        return isMenuOpen ? this.closeMenu() : this.openMenu();
    }

    render() {
        const { className, logo, navigationLinks, pagePath, menuItems, socialNetworksLinks, withAnimationBorder } =
            this.props;

        const { isShowShadow, isMenuOpen, isShowHeader, isShowMobileLogo } = this.state;

        const classes = classNames(
            'ltn-header-v2',
            isShowShadow && 'ltn-header-v2--with-shadow',
            isMenuOpen && 'ltn-header-v2-mobile-menu-is-open',
            !isShowHeader && 'ltn-header-v2--hide',
            className
        );

        return (
            <header ref={this.header} className={classes}>
                <div className="ltn-header-v2-layout">
                    <nav className="ltn-header-v2-navigation">
                        {!isMenuOpen && (
                            <>
                                <Logo link={logo?.link} sources={logo?.sources} isMobileLogo={isShowMobileLogo} />
                                <div className="ltn-header-v2-nav-links">
                                    <NavigationLinkWrapper
                                        navigationLinks={navigationLinks}
                                        pagePath={pagePath}
                                        withAnimationBorder={withAnimationBorder}
                                        isActiveHeader={isShowHeader}
                                    />
                                </div>
                                <a href={TRADING_PLATFORM_URL} className="ltn-navigation-v2-link ltn-header-v2-tp-link">
                                    <img
                                        src={HEADER_NAVIGATION_LINKS.tradingPlatform.icon}
                                        height={28}
                                        width={28}
                                        alt=""
                                    />
                                </a>
                            </>
                        )}
                        <button type="button" className="ltn-header-v2-button" onClick={this.toggleMenu}>
                            {isMenuOpen ? (
                                <img src={DEFAULT_LABELS.menuIcons.close} width={17} height={17} alt="" />
                            ) : (
                                <img src={DEFAULT_LABELS.menuIcons.open} width={24} height={17} alt="" />
                            )}
                        </button>
                        {isMenuOpen && (
                            <Menu
                                menuItems={menuItems}
                                onCloseMenu={this.closeMenu}
                                socialNetworksLinks={socialNetworksLinks}
                                pagePath={pagePath}
                            />
                        )}
                    </nav>
                </div>
            </header>
        );
    }
}

HeaderV2.propTypes = {
    className: PropTypes.string,
    logo: PropTypes.shape(),
    navigationLinks: PropTypes.shape(),
    menuItems: PropTypes.arrayOf(PropTypes.shape()),
    socialNetworksLinks: PropTypes.arrayOf(PropTypes.shape()),
    pagePath: PropTypes.string,
    withAnimationBorder: PropTypes.bool
};

HeaderV2.defaultProps = {
    className: undefined,
    logo: undefined,
    navigationLinks: undefined,
    menuItems: undefined,
    socialNetworksLinks: undefined,
    pagePath: undefined,
    withAnimationBorder: undefined
};
